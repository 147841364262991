import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';
import cx from 'classnames';

import useStepper from '@components/Stepper/hook';

import './Stepper.scss';

export default function Stepper({ stepNames, currStep }) {
    const { t } = useTranslation();
    const { currStepIndex, camelStepNames } = useStepper(stepNames, currStep);

    return (
        <Box className="stepper">
            <Box className="stepperProgressBarWrapper">
                {stepNames.map((stepName, index) => {
                    return (
                        <Box
                            className={cx([
                                'stepperProgressBar',
                                index <= currStepIndex &&
                                    'markedStepperProgressBar'
                            ])}
                            style={{
                                width: `${(1 / stepNames.length) * 100}%`
                            }}
                            key={index}
                        />
                    );
                })}
            </Box>
            <Box className="stepperContent">
                <Box className="stepIndex">
                    {`${t('common.step.name')} `}
                    <span className="currStepIndex">{currStepIndex + 1}</span>
                    {`/${stepNames.length}`}
                </Box>
                <Box className="stepName">
                    {t('common.step.' + camelStepNames[currStepIndex])}
                </Box>
            </Box>
        </Box>
    );
}
