import './Header.scss';

const Header = () => {
    return null;
    // return (
    //     <Box className="headerWrapper">
    //         <Box className="logoBrandWrapper">{/* <LogoBrand /> */}</Box>
    //     </Box>
    // );
};

export default Header;
